var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    'rdr-btn',
    'rdr-btn--small',
    { 'rdr-btn--primary': _vm.primary },
    'hide-print',
    'hide-mobile'
  ],attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.print.apply(null, arguments)}}},[_c('i',{staticClass:"rdr-btn__icon material-icons"},[_vm._v("print")]),_vm._v(" "),_c('span',[_vm._v("Imprimir")])])
}
var staticRenderFns = []

export { render, staticRenderFns }