<template>
  <div>
  <!-- TEMPORAL <el-dialog
    :visible.sync="show"
    class="radar-dialog contact-dialog">
    <div slot="title">
      <div class="radar-dialog__title"><span>¡Contáctanos!</span></div>
      <div class="radar-dialog__subtitle subitile">
        <i class="contact-dialog__icon socicon-whatsapp" /> Llámanos o escríbenos por WhatsApp al <strong>+56951203573</strong>
      </div>
    </div> -->

    <div class="conten-forms">
      <div
        v-if="!submitted"
        style="text-align:left">
        <p>Si prefieres, puedes dejar tus datos aquí para que te contactemos:</p>
      </div>
      <form
        v-if="!submitted"
        class="contact-dialog__form"
        @submit.prevent="submitContact">
        <input
          v-model="name"
          class="contact-dialog__input rdr-input rdr-input--block"
          type="text"
          placeholder="Tu nombre"
          required >
        <input
          v-model="email"
          class="contact-dialog__input rdr-input rdr-input--block"
          type="email"
          placeholder="Tu correo electrónico"
          required >
        <input
          v-model="phone"
          class="contact-dialog__input rdr-input rdr-input--block"
          type="number"
          placeholder="Tu número de teléfono"
          required >

        <school-select
          v-model="dataChosenSchool"
          class="contact-dialog__input rdr-select relation--picker__select"
          placeholder="Colegio o RBD"
          required
          @input="getChosenSchool"/>

        <v-ener-select
          :options="relations"
          class="rdr-select relation--picker__select"
          required
          placeholder="Tu relación con el establecimiento"
          @input="onSelectChange($event)"/>
        <span
          v-if="chosenSchool"
          class="contact-dialog__school">({{ chosenSchool.name | startCase }})</span>
        <div v-else>
          <span
            v-if="viewError"
            class="contact-dialog__required">
            * (Elige colegio y relación con el establecimiento.)
          </span>
        </div>
        <button
          :disabled="submitting"
          class="rdr-btn rdr-btn--block"
          type="submit">
          <span v-if="!submitting">Contáctenme</span>
          <span v-if="submitting">Enviando...</span>
        </button>
      </form>

      <div
        v-if="submitted"
        class="contact-dialog__form contact-dialog__form--submitted">
        <br>
        <span>¡Hemos recibido tu mensaje!<br><br>Te contactaremos a la brevedad.</span>
      </div>
    </div>
  <!-- TEMPORAL </el-dialog>-->
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import { Dialog } from 'element-ui';
import AuthMixin from '../../mixins/auth';
import ContactApi from '../../api/contact';
import SchoolSelect from '../../components/school-select.vue';

export default {
  components: {
    'el-dialog': Dialog,
    SchoolSelect,
  },
  mixins: [AuthMixin],
  data() {
    return {
      submitting: false,
      relations: [
        { key: 'sostenedor', label: 'Sostenedor' },
        { key: 'director', label: 'Director' },
        { key: 'equipoDirectivo', label: 'Integrante Equipo Directivo' },
        { key: 'profesor', label: 'Profesor' },
        { key: 'apoderado', label: 'Apoderado' },
        { key: 'other', label: 'Otro' },
      ],
      dataChosenSchool: null,
      selected: null,
      viewError: false,
    };
  },
  computed: {
    ...mapState('options', ['chosenSchoolIds']),
    ...mapMutations('options', ['chosenSchool']),
    name: {
      get() { return this.$store.state.ui.contact.name; },
      set(value) {
        this.$store.commit({
          type: 'ui/updateContactData',
          key: 'name',
          value,
        });
      },
    },
    email: {
      get() { return this.$store.state.ui.contact.email; },
      set(value) {
        this.$store.commit({
          type: 'ui/updateContactData',
          key: 'email',
          value,
        });
      },
    },
    phone: {
      get() { return this.$store.state.ui.contact.phone; },
      set(value) {
        this.$store.commit({
          type: 'ui/updateContactData',
          key: 'phone',
          value,
        });
      },
    },
    submitted: {
      get() {
        return this.$store.state.ui.dialogs.contact.submitted;
      },
      set(value) {
        this.$store.commit({
          type: 'ui/SET_DIALOG_DATA',
          dialog: 'contact',
          key: 'submitted',
          value,
        });
      },
    },
    show: {
      get() { return this.$store.state.ui.dialogs.contact.open; },
      set(value) {
        this.$store.commit({
          type: 'ui/SET_DIALOG_STATE',
          dialog: 'contact',
          state: value,
        });
      },
    },
  },
  methods: {
    onSelectChange(event) {
      if (event === null) {
        this.selected = null;
        this.viewError = true;
      } else {
        this.viewError = false;
        this.selected = event.key;
        this.$store.commit({
          type: 'ui/updateContactData',
          key: 'relation',
          value: event.key,
        });
      }
    },

    getChosenSchool(data) {
      this.dataChosenSchool = data;
      this.viewError = false;
      if (data === null) {
        this.viewError = true;
      }
    },

    submitContact() {
      if (this.dataChosenSchool === null || this.selected === null) {
        this.viewError = true;
      } else {
        this.viewError = false;
        this.submitting = true;
        ContactApi.submit({
          contactType: 'notification',
          currentSchool: this.dataChosenSchool.id,
          email: this.email,
          name: this.name,
          otherSchools: this.chosenSchoolIds,
          phone: this.phone,
          relation: this.selected,
        }).then(() => {
          this.submitting = false;
          this.submitted = true;
          this.$ga.event({
            eventCategory: 'ContactForm',
            eventAction: 'SendContactInfo',
            eventLabel: 'Contact',
          });
          setTimeout(() => {
            location.href = "./";
          }, 600);
        }).catch(() => {
          console.log('ALGO PASO .... ');
        })
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../../../styles/app/variables";

  .radar-dialog {
    a {
      color: $primary-color;
      text-decoration: none;
      border-bottom: 1px dashed;
    }

    .el-dialog {
      background-color: #FCFCFC;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 90%;
      }
    }

    :global(.el-dialog) {
      &__body {
        padding: 60px 30px;
        height: 100%;
      }
    }

    &__title {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 8px;
      text-align: center;
    }

    &__subtitle {
      font-size: 18px;
      text-align: center;

      &--light {
        font-weight: 300;
        font-size: 12pt;
      }
    }

    &__divider {
      color: $light-color;
      opacity: 0.4;
      margin: 25px auto 15px auto;
    }
  }

  .contact-dialog {
    &__icon {
      color: $success-color;
    }

    &__form {
      width: 90%;
      margin: 0 auto;
      margin-top: 20px;

      @media only screen and (max-width: #{$mobile-breakpoint}) {
        width: 100%;
      }

      &--submitted {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 24px;
        font-size: 20px;
        color: $success-color;
      }
    }

    &__input {
      margin-bottom: 15px;
    }
    &__school {
      display: block;
      margin: 15px auto;
      color: $light-color;
      font-weight: 400;
      text-align: center;
    }
    &__required {
      display: block;
      margin: 15px auto;
      color: red;
      font-weight: 400;
      text-align: center;
    }
    .rdr-btn {
      margin-top: 35px;
    }
  }

  .open-indicator {
    right: 32px;
    bottom: 20px;

    &:before {
      display: none;
    }

    &:after {
      color: #4266f7 !important;
      font-family: 'Material Icons';
      font-size: 25px;
      font-style: normal;
      content: 'keyboard_arrow_down' !important;
    }
  }

  // .conten-forms {
  //   height: 100%;
  //   overflow: auto;
  // }

</style>
