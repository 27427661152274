var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    _vm.$style.rdrPicker,
    {[_vm.$style.rdrPickerSmall]: _vm.size === 'small'},
    {[_vm.$style.rdrPickerInline]: _vm.pickerStyle === 'inline'},
    {[_vm.$style.rdrPickerBorder]: _vm.pickerStyle !== 'round'},
    {[_vm.$style.rdrPickerRound]: _vm.pickerStyle === 'round'}]},[(_vm.iconCalendar)?[_vm._m(0)]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"contenSelect"},[_c('select',{class:[
        _vm.$style.rdrPickerSelect,
        {[_vm.$style.rdrPickerDisabled]: _vm.options.length === 0 || _vm.disabled}],attrs:{"disabled":_vm.options.length === 0 || _vm.disabled},domProps:{"value":_vm.value},on:{"change":function($event){return _vm.handleChange($event.target && $event.target.value)}}},[(_vm.emptyChoice)?_c('option',{domProps:{"selected":_vm.value === null,"value":null}},[_vm._v("\n        "+_vm._s(_vm.noChoice)+"\n      ")]):_vm._e(),_vm._v(" "),_vm._l((_vm.options),function(option){return _c('option',{key:_vm.getOptionValue(option),domProps:{"selected":_vm.isSelected(option),"value":_vm.getOptionValue(option)}},[_vm._v("\n        "+_vm._s(_vm.getOptionLabel(option))+"\n      ")])})],2),_vm._v(" "),_c('div',{class:[
        _vm.$style.rdrPickerCurrent,
        {[_vm.$style.rdrPickerCurrentDisabled]: _vm.options.length === 0 || _vm.disabled}]},[(!_vm.loading && _vm.options.length && _vm.value)?_c('span',{class:_vm.$style.rdrPickerLabel},[_vm._v("\n        "+_vm._s(_vm.getOptionLabel(_vm.value))+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.options.length && !_vm.value)?_c('span',{class:_vm.$style.rdrPickerLabel},[_vm._v("\n        "+_vm._s(_vm.noChoice)+"\n      ")]):_vm._e(),_vm._v(" "),(!_vm.loading && _vm.options.length > 0)?_c('i',{class:[
          {[_vm.$style.rdrPickerArrowDisabled]: _vm.options.length === 0 || _vm.disabled},
          _vm.$style.rdrPickerArrow,
          _vm.$style.customMaterialIcons,
          'material-icons',
          'hide-print']},[_vm._v("\n        keyboard_arrow_down\n      ")]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.loadingText))]):(!_vm.options.length)?_c('span',[_vm._v(_vm._s(_vm.noOptions))]):_vm._e()])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contenlogo"},[_c('i',{staticClass:"clg-check-time"})])
}]

export { render, staticRenderFns }