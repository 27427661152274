var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{class:[
      _vm.$style.sidebar,
      { [_vm.$style.hidden]: !_vm.displayMenu },
      { 'menu-hover': !_vm.displayMenu },
    ],attrs:{"data-step":_vm.overviewTutorial.step,"data-intro":_vm.overviewTutorial.description},on:{"mouseover":function($event){return _vm.menutipoHoverOn()},"mouseout":function($event){return _vm.menutipoHoverOff()}}},[_c('div',{class:_vm.$style.menu},[(_vm.loadMenu)?_vm._l((_vm.itemsMenu),function(item){return _c('div',{key:item.key,class:_vm.$style.item,attrs:{"data-step":_vm.itemTutorial(item).step,"data-intro":_vm.itemTutorial(item).description}},[_c('div',{class:[
              _vm.$style.itemLabel,
              { 'center-icon-menu': !_vm.displayMenu,
                [`${_vm.$style.active} ${_vm.$style.selected}`]: item.select,
              }],on:{"click":function($event){return _vm.handleItemLabelClick(item, true)}}},[_c('div',{class:_vm.$style.itemLabelText},[(item.icon)?_c('i',{class:[_vm.$style.icon, item.icon]}):_vm._e(),_vm._v(" "),(item.pro && _vm.freeOrGuestUser)?_c('span',{class:_vm.$style.proBadge},[_vm._v("\n                PRO\n              ")]):_vm._e(),_vm._v(" "),_c('span',{class:[{ 'hidden-text-menu': !_vm.displayMenu }],staticStyle:{"margin-left":"6px"}},[_vm._v("\n                "+_vm._s(item.label)+"\n              ")])])])])}):_vm._e()],2),_vm._v(" "),(_vm.isLoggedIn)?_c('div',{staticClass:"menudown"},[_c('div',{staticClass:"items-menu-down",style:(!_vm.displayMenu ? {'text-align':'center'} : ''),on:{"click":function($event){return _vm.evaluateTuto()}}},[_c('i',{staticClass:"clg-share_multimedia"}),_vm._v(" "),_c('span',{class:[{ 'hidden-text-menu': !_vm.displayMenu }],staticStyle:{"margin-left":"6px"}},[_vm._v("Tutorial")])]),_vm._v(" "),_c('div',{staticClass:"items-menu-down",style:(!_vm.displayMenu ? {'text-align':'center'} : '')},[(_vm.displayMenu)?[_c('help-button')]:[_c('help-button',{attrs:{"text-display":false}})]],2),_vm._v(" "),_c('div',{staticClass:"items-menu-down",style:(!_vm.displayMenu ? {'text-align':'center'} : ''),on:{"click":function($event){return _vm.handleItemLabelClick(_vm.itemConfig, true)}}},[_c('i',{staticClass:"clg-config"}),_vm._v(" "),_c('span',{class:{ 'hidden-text-menu': !_vm.displayMenu },staticStyle:{"margin-left":"6px"}},[_vm._v("Configuración")])])]):_vm._e(),_vm._v(" "),(_vm.isFreeUser || !_vm.isLoggedIn)?_c('div',{class:_vm.$style.footer},[_vm._m(0)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"color":"#FFF !important"},attrs:{"href":"https://www.radarescolar.cl/"}},[_c('span',[_vm._v("Más información")])])
}]

export { render, staticRenderFns }