<template>
  <div class="report-description">
    <div v-if="loading">
      <transition name="fade">
        <loading-overlay/>
      </transition>
    </div>
    <template v-if="description">
      <el-popover
        :content="description"
        :title="title"
        :value="active"
        trigger="manual"
        placement="bottom-start"
        popper-class="report-description-popper"
      >
        <span
          v-if="newStayle"
          slot="reference"
          :class="['iconInfoAlertTool', 'hide-print', classIconInfo]"
          @click="changueState()"
          @mouseenter="handleMouseEnter('info')"
          @mouseleave="mouseEntered = false">
          <i
            class="clg-info" />
        </span>
        <i
          v-else
          slot="reference"
          class="info material-icons"
          @click="mouseClicked = !mouseClicked"
          @blur="mouseClicked = false"
          @mouseenter="handleMouseEnter('info')"
          @mouseleave="mouseEntered = false"
        >
          info
        </i>
      </el-popover>
    </template>

    <template v-if="viewIconPrint && renderIcon">
      <el-popover
        content="Imprimir grafica"
        :value="active_II"
        trigger="manual"
        placement="bottom-start"
        popper-class="report-description-popper"
      >
        <span
          v-if="printEnabled"
          slot="reference"
          :class="['iconInfoAlertTool', 'hide-print', classIconPrint]"
          @click="printChart()"
          @mouseenter="handleMouseEnter('print')"
          @mouseleave="mouseEntered_II = false"
        >
          <i class="clg-print" />
        </span>
        <span
          v-else
          slot="reference"
          :class="['printDesabled', 'hide-print', classIconPrint]"
        >
          <i class="clg-print" />
        </span>
      </el-popover>
    </template>

    <template v-if="viewIconDown && renderIcon">
      <el-popover
        content="Descargar imagen de grafica"
        :value="active_III"
        trigger="manual"
        placement="bottom-start"
        popper-class="report-description-popper"
      >
        <span
          slot="reference"
          :class="['iconInfoAlertTool', 'hide-print', classIconDown]"
          @click="downChartConfir()"
          @mouseenter="handleMouseEnter('down')"
          @mouseleave="mouseEntered_III = false"
        >
          <i class="clg-download" />
        </span>
      </el-popover>
    </template>

    <el-dialog
      v-if="viewIconDown && renderIcon"
      :visible.sync="showChangeDown"
      append-to-body
    >
      <h2 slot="title">Descargar imagen de grafica</h2>
      <div>
        <div class="contencol">
          <template v-if="viewDown">
            <img src="/fuente-datos.svg">
            <h3>Para generar una imagen de la gráfica y descargarla,<br>de click sobre el botón (<b>Descargar</b>).</h3>
            <button class="rdr-btn rdr-btn--primary school__platforms-button" @click="downChart()">
              <i class="clg-download-cloud"></i> Descargar
            </button>
          </template>
          <template v-else>
            <img src="/cargando_colegium.gif">
            <h3>Generando imagen...</h3>
          </template>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapMutations } from 'vuex';
import { Popover, Dialog } from 'element-ui';
import html2canvas from 'html2canvas';


Vue.use(html2canvas);

export default {
  name: 'ReportDescription',
  components: {
    'el-popover': Popover,
    'el-dialog': Dialog,
  },
  props: {
    description: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    newStayle: {
      type: Boolean,
      default: false,
    },
    viewIconInfo: {
      type: Boolean,
      default: true,
    },
    viewIconPrint: {
      type: Boolean,
      default: false,
    },
    viewIconDown: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mouseClicked: false,
      mouseEntered: false,
      mouseClicked_II: false,
      mouseEntered_II: false,
      mouseClicked_III: false,
      mouseEntered_III: false,
      activeAlert: true,
      firstLoad: true,
      classIconInfo: 'rightP1',
      classIconPrint: 'rightP2',
      classIconDown: 'rightP3',
      showChangeDown: false,
      viewDown: true,
      printEnabled: true,
      loading: false,
      renderIcon: false,
    };
  },
  computed: {
    active() {
      return this.mouseClicked || this.mouseEntered;
    },
    active_II() {
      return this.mouseClicked_II || this.mouseEntered_II;
    },
    active_III() {
      return this.mouseClicked_III || this.mouseEntered_III;
    },
    ...mapState({
      viewAlert: state => state.ui.stateAlert,
    }),
    ...mapState({titleText: state => state.ui.tituloMod}),
  },
  watch: {
    viewAlert() {
      this.activeAlert = this.viewAlert;
    },
  },
  mounted() {
    this.openAlertInfo(false);
    if(this.titleText.key === 'attendance') this.renderIcon = true;
    // if(!this.viewIconInfo) {
    //   this.classIconPrint = 'rightP1';
    //   this.classIconDown = 'rightP2';
    // }
    // if(!this.viewIconInfo && !this.viewIconPrint) this.classIconDown = 'rightP1';
  },
  methods: {
    ...mapMutations({
      openAlertInfo: 'ui/openAlertInfo',
    }),
    handleMouseEnter(iconType) {
      if (iconType == 'info') {
        if (this.mouseClicked) return;
        this.mouseEntered = true;
      }
      if (iconType == 'print') {
        if (this.mouseClicked_II) return;
        this.mouseEntered_II = true;
      }
      if (iconType == 'down') {
        if (this.mouseClicked_III) return;
        this.mouseEntered_III = true;
      }
    },
    changueState() {
      const timeOn = 200;
      const first = this.firstLoad;
      if (first) {
        this.openAlertInfo(true)
        this.firstLoad = false
      }
      setTimeout(() => {
        let actions = this.activeAlert;
        this.activeAlert = !actions;
        this.openAlertInfo(this.activeAlert);
      }, timeOn);
    },
    printChart() {
      this.printEnabled = false;
      this.loading = true;
      if (document.getElementsByClassName('right-arrow').length) {
        let rowsIcon = document.getElementsByClassName('right-arrow');
        for(let i=0; i<rowsIcon.length; i++){
          rowsIcon[i].click();
        };
      }
      setTimeout(() => {
        this.loading = false;
        this.mouseEntered_II = false;
        this.createImageTable();
      }, 900);
    },
    createImageTable() {
      const elemShow = document.getElementsByClassName("print-only");
      const elemHide = document.getElementsByClassName("hide-print");
      for(let i=0; i<elemShow.length; i++){
        elemShow[i].classList.add('dth-showPrint');
        elemShow[i].classList.remove('print-only');
      };
      for(let i=0; i<elemHide.length; i++){
        elemHide[i].classList.add('print-only');
      };
      setTimeout(() => {
        html2canvas(
          document.querySelector("#dth-contenReport")
        ).then(canvas => {
          let image = canvas.toDataURL("image/jpeg", 1.0);
          let wModal = document.documentElement.clientWidth;
          let centerLeft = (wModal / 2) - 450;
          let ventanaTmp = window.open('', '', 'width=900,height=750,left='+centerLeft);
          ventanaTmp.document.write('<img src="'+image+'" width="650" style="position:absolute;top:4px;left:0;right:0;margin:auto;">');
          setTimeout(() => {
            ventanaTmp.print( );
            const newelemHide = document.getElementsByClassName("dth-showPrint");
            for(let i=0; i<newelemHide.length; i++){
              newelemHide[i].classList.add('print-only');
              newelemHide[i].classList.remove('dth-showPrint');
            };
            for(let i=0; i<elemHide.length; i++){
              elemHide[i].classList.remove('print-only');
            };
            setTimeout(() => {
              ventanaTmp.close();
            }, 3000);
          }, 400);
        }
        );
      }, 300);
      setTimeout(() => {
        this.printEnabled = true;
      }, 4000);
    },
    downChartConfir() {
      this.showChangeDown = true;
    },
    downChart() {
      this.showChangeDown = true;
      this.viewDown = false;
      let time = Date.now();
      if (document.getElementsByClassName('right-arrow').length) {
        let rowsIcon = document.getElementsByClassName('right-arrow');
        for(let i=0; i<rowsIcon.length; i++){
          rowsIcon[i].click();
        };
      }
      html2canvas(
        document.querySelector("#downTable")).then(canvas => {
          let image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
          let a = document.createElement('a');
          a.href = image;
          a.download = 'Diagrama_'+time+'.png';
          a.click();
        }
      );
      setTimeout(() => {
        this.showChangeDown = false;
        this.viewDown = true;
      }, 900);
    }
  },
};

</script>

<style lang="scss">
@import "../../../styles/app/variables";
$small-window-breakpoint: 900px;
$big-window-breakpoint: 1350px;

.report-description-popper {
  max-height: 60vh;
  max-width: 80vw;
  margin-right: 1rem;
  color: $main-text-color;
  white-space: pre-line;
  word-break: keep-all;

  @media only screen and (max-width: $big-window-breakpoint) {
    max-width: 70vw;
  }

  @media only screen and (max-width: $small-window-breakpoint) {
    max-width: 60vw;
  }
  @media only screen and (max-width: $mobile-breakpoint) {
    max-width: 85vw;
    max-height: 60vh;
    overflow-y: auto;
  }
}

.report-description {
  .info {
    margin-left: 20%;
  }
  .info:hover {
    cursor: pointer;
    color: $primary-color;
  }
  .info:focus {
    color: $primary-color;
  }
}
.contencol {
  background: #E7EFFF;
  border-radius: 10px;
  padding: 40px 0px;
  text-align: center;
  margin-top: 25px;
}
</style>
