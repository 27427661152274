import { render, staticRenderFns } from "./historic-indicator-picker.vue?vue&type=template&id=474064d6&scoped=true"
import script from "./historic-indicator-picker.vue?vue&type=script&lang=js"
export * from "./historic-indicator-picker.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "474064d6",
  null
  
)

export default component.exports