var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"toggle"}},[(_vm.displayMobileMenu)?_c('div',{staticClass:"mobile-only mobile-menu__container"},[_c('div',{staticClass:"mobile-menu__content",class:{ 'mobile-menu__content--small' : _vm.freeOrGuestUser }},[_vm._l((_vm.items),function(item){return _c('ul',{key:`mobile-menu-${item.key}`},[_c('li',{class:[
            {'mobile-menu__link': _vm.clickable(item)},
            { 'mobile-menu__link--active' : _vm.clickable(item) && item.routes && _vm.routesMatch(item.routes) },
          ],on:{"click":function($event){return _vm.handleItemClick(item)}}},[_c('span',{staticClass:"mobile-menu__link-text"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),(item.pro && _vm.freeOrGuestUser)?_c('span',{staticClass:"mobile-menu__pro-badge"},[_vm._v("PRO")]):_vm._e()]),_vm._v(" "),_c('ul',_vm._l((item.children),function(subitem){return _c('li',{key:`mobile-menu-${subitem.key}`,class:[
              'mobile-menu__link',
              { 'mobile-menu__link--active' : _vm.routesMatch(subitem.routes) }
            ],on:{"click":function($event){return _vm.linkWithToggle(subitem.link)}}},[_c('span',{staticClass:"mobile-menu__link-text"},[_vm._v(_vm._s(subitem.label))]),_vm._v(" "),(subitem.pro && _vm.freeOrGuestUser)?_c('span',{staticClass:"mobile-menu__pro-badge"},[_vm._v("PRO")]):_vm._e()])}),0)])}),_vm._v(" "),(_vm.isLoggedIn)?_c('ul',[_c('li',[_c('span',{staticClass:"mobile-menu__link-text"},[_vm._v("Mi Cuenta")]),_vm._v(" "),_c('span',{staticClass:"mobile-menu__link-text--blur"},[_vm._v(" — "+_vm._s((_vm.currentUser && _vm.currentUser.name) || 'Cargando...'))])]),_vm._v(" "),_c('ul',[_c('li',{staticClass:"mobile-menu__link",class:{ 'mobile-menu__link--active' : _vm.routeMatches('settings') },on:{"click":function($event){return _vm.linkWithToggle('settingsLink')}}},[_c('span',{staticClass:"mobile-menu__link-text"},[_vm._v("Configuración")])]),_vm._v(" "),_c('li',{staticClass:"mobile-menu__link",on:{"click":_vm.logout}},[_c('span',{staticClass:"mobile-menu__link-text"},[_vm._v("Cerrar sesión")])])])]):_vm._e()],2),_vm._v(" "),(_vm.freeOrGuestUser)?_c('div',{staticClass:"mobile-menu__footer"},[(_vm.freeOrGuestUser)?_c('router-link',{staticClass:"rdr-btn rdr-btn--small rdr-btn--important",attrs:{"to":_vm.proQuoteLink}},[_c('span',[_vm._v("Cotiza")])]):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn)?_c('a',{staticClass:"rdr-btn rdr-btn--small rdr-btn--primary",on:{"click":_vm.signUp}},[_c('span',[_vm._v("Registrarse")])]):_vm._e(),_vm._v(" "),(!_vm.isLoggedIn)?_c('a',{staticClass:"rdr-btn rdr-btn--small",on:{"click":_vm.loginPopUp}},[_c('span',[_vm._v("Iniciar sesión")])]):_vm._e()],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }